var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":_vm.app.appLogoImage,"alt":"logo"}}),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" "+_vm._s(_vm.app.appName)),_c('sup',{staticClass:"brand-trademark"},[_vm._v("®")])])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{staticClass:"w-50",attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-25",attrs:{"title-tag":"h2"}},[_vm._v(" Login ")]),_c('b-alert',{attrs:{"show":_vm.hasError,"variant":"danger"}},[_vm._v(_vm._s(_vm.state.error))]),(!_vm.state.changePassword && !_vm.state.resetPassword)?[_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.login)}}},[_c('b-form-group',{attrs:{"label":"Username","label-for":"login-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-username","trim":"","state":errors.length > 0 ? false:null,"name":"login-username","placeholder":""},model:{value:(_vm.account.username),callback:function ($$v) {_vm.$set(_vm.account, "username", $$v)},expression:"account.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name:'auth-forgot-password' }}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","trim":"","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.account.password),callback:function ($$v) {_vm.$set(_vm.account, "password", $$v)},expression:"account.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.state.resendSignUp)?_c('b-button',{attrs:{"variant":"secondary","block":"","disabled":_vm.state.loading},on:{"click":_vm.resendSignUp}},[(_vm.state.loading)?_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"arrow-clockwise","animation":"spin"}}):_vm._e(),_vm._v(" Resend Sign Up ")],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.state.loading}},[(_vm.state.loading)?_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"arrow-clockwise","animation":"spin"}}):_vm._e(),_vm._v(" Sign in ")],1)],1)]}}],null,false,1076914776)})]:_vm._e(),(_vm.state.resetPassword)?[_c('validation-observer',{ref:"newPasswordValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.resetPassword)}}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Password Reset")]),_c('small',[_vm._v("Your password was reset by an administrator. Check your email for a verification code, and create a new password.")]),_c('b-form-group',{staticClass:"mt-1",attrs:{"label":"Username","label-for":"reset-password-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reset-password-username","disabled":""},model:{value:(_vm.account.username),callback:function ($$v) {_vm.$set(_vm.account, "username", $$v)},expression:"account.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Verification Code","label-for":"reset-password-code"}},[_c('validation-provider',{attrs:{"vid":"code","name":"Verification Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-code","state":errors.length > 0 ? false:null,"placeholder":""},model:{value:(_vm.account.code),callback:function ($$v) {_vm.$set(_vm.account, "code", $$v)},expression:"account.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"New Password","label-for":"reset-password-new-password"}},[_c('validation-provider',{attrs:{"vid":"newPassword","name":"New Password","rules":"required|min:8|confirmed:newPasswordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.account.newPassword),callback:function ($$v) {_vm.$set(_vm.account, "newPassword", $$v)},expression:"account.newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Confirm New Password","label-for":"reset-password-new-password-confirm"}},[_c('validation-provider',{attrs:{"vid":"newPasswordConfirm","name":"New Password","rules":"required|min:8|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-new-password-confirm","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.account.newPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.account, "newPasswordConfirm", $$v)},expression:"account.newPasswordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.state.loading}},[(_vm.state.loading)?_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"arrow-clockwise","animation":"spin"}}):_vm._e(),_vm._v(" Update Password ")],1)],1)]}}],null,false,933897484)})]:_vm._e(),(_vm.state.changePassword)?[_c('validation-observer',{ref:"newPasswordValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return handleSubmit(_vm.setNewPassword)}}},[_c('h5',[_vm._v("Enter New Password")]),_c('b-form-group',[_c('validation-provider',{attrs:{"vid":"newPassword","name":"New Password","rules":"required|min:8|confirmed:newPasswordConfirm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.account.newPassword),callback:function ($$v) {_vm.$set(_vm.account, "newPassword", $$v)},expression:"account.newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('validation-provider',{attrs:{"vid":"newPasswordConfirm","name":"New Password","rules":"required|min:8|confirmed:newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.account.newPasswordConfirm),callback:function ($$v) {_vm.$set(_vm.account, "newPasswordConfirm", $$v)},expression:"account.newPasswordConfirm"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.state.loading}},[(_vm.state.loading)?_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"arrow-clockwise","animation":"spin"}}):_vm._e(),_vm._v(" Sign in ")],1)],1)]}}],null,false,3406498242)})]:_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }