<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img :src="app.appLogoImage" alt="logo" />
        <h2 class="brand-text text-primary ml-1">
          {{ app.appName }}<sup class="brand-trademark">&reg;</sup>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img fluid :src="imgUrl" class="w-50" alt="Login V2"/>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-25">
            Login
          </b-card-title>
<!--          <b-card-text class="mb-2">
            Please login to your account using your
          </b-card-text>-->

          <b-alert :show="hasError" variant="danger">{{ state.error }}</b-alert>

<!--          <amplify-authenticator></amplify-authenticator>-->

          <!-- form -->
          <template v-if="!state.changePassword && !state.resetPassword">
            <validation-observer ref="loginValidation" v-slot="{handleSubmit}">
              <b-form class="auth-login-form mt-2" @submit.stop.prevent="handleSubmit(login)">
                <!-- username -->
                <b-form-group label="Username" label-for="login-username">
                  <validation-provider #default="{ errors }" name="Username" rules="required">
                    <b-form-input
                        id="login-username"
                        v-model="account.username" trim
                        :state="errors.length > 0 ? false:null"
                        name="login-username"
                        placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label>Password</label>
                    <b-link :to="{ name:'auth-forgot-password' }">
                      <small>Forgot Password?</small>
                    </b-link>
                  </div>
                  <validation-provider #default="{ errors }" name="Password" rules="required">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                      <b-form-input
                          id="login-password"
                          v-model="account.password" trim
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
<!--                <b-form-group>
                  <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1">
                    Remember Me
                  </b-form-checkbox>
                </b-form-group>-->

                <!-- submit buttons -->
                <b-button v-if="state.resendSignUp" variant="secondary" block :disabled="state.loading" @click="resendSignUp">
                  <b-icon v-if="state.loading" icon="arrow-clockwise" animation="spin" class="mx-2"></b-icon> Resend Sign Up
                </b-button>
                <b-button type="submit" variant="primary" block :disabled="state.loading">
                  <b-icon v-if="state.loading" icon="arrow-clockwise" animation="spin" class="mx-2"></b-icon> Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </template>

          <template v-if="state.resetPassword">
            <validation-observer ref="newPasswordValidation" v-slot="{handleSubmit}">
              <b-form class="auth-login-form mt-2" @submit.stop.prevent="handleSubmit(resetPassword)">
                <h5 class="mb-0">Password Reset</h5>
                <small>Your password was reset by an administrator. Check your email for a verification code, and create a new password.</small>

                <b-form-group label="Username" label-for="reset-password-username" class="mt-1">
                  <validation-provider #default="{ errors }" name="Username" rules="required">
                    <b-form-input
                        id="reset-password-username"
                        v-model="account.username" disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Verification Code" label-for="reset-password-code">
                  <validation-provider #default="{ errors }" vid="code" name="Verification Code" rules="required">
                    <b-form-input
                        id="reset-password-code"
                        v-model="account.code"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        placeholder=""
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- New Password -->
                <b-form-group label="New Password" label-for="reset-password-new-password">
                  <validation-provider #default="{ errors }" vid="newPassword" name="New Password" rules="required|min:8|confirmed:newPasswordConfirm">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                      <b-form-input
                          id="reset-password-new-password"
                          v-model="account.newPassword"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- New Password Confirm -->
                <b-form-group label="Confirm New Password" label-for="reset-password-new-password-confirm">
                  <validation-provider #default="{ errors }" vid="newPasswordConfirm" name="New Password" rules="required|min:8|confirmed:newPassword">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                      <b-form-input
                          id="reset-password-new-password-confirm"
                          v-model="account.newPasswordConfirm"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button type="submit" variant="primary" block :disabled="state.loading">
                  <b-icon v-if="state.loading" icon="arrow-clockwise" animation="spin" class="mx-2"></b-icon> Update Password
                </b-button>
              </b-form>
            </validation-observer>
          </template>

          <template v-if="state.changePassword">
            <validation-observer ref="newPasswordValidation" v-slot="{handleSubmit}">
              <b-form class="auth-login-form mt-2" @submit.stop.prevent="handleSubmit(setNewPassword)">
                <h5>Enter New Password</h5>
                <!-- New Password -->
                <b-form-group>
                  <validation-provider #default="{ errors }" vid="newPassword" name="New Password" rules="required|min:8|confirmed:newPasswordConfirm">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                      <b-form-input
                          id="login-password"
                          v-model="account.newPassword"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- New Password Confirm -->
                <b-form-group>
                  <validation-provider #default="{ errors }" vid="newPasswordConfirm" name="New Password" rules="required|min:8|confirmed:newPassword">
                    <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                      <b-form-input
                          id="login-password"
                          v-model="account.newPasswordConfirm"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-button type="submit" variant="primary" block :disabled="state.loading">
                  <b-icon v-if="state.loading" icon="arrow-clockwise" animation="spin" class="mx-2"></b-icon> Sign in
                </b-button>
              </b-form>
            </validation-observer>
          </template>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { Auth } from 'aws-amplify';
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { setAccount } from '@/scripts/account'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      app: $themeConfig.app,
      account: {
        username: '',
        password: '',
        newPassword: '',
        newPasswordConfirm: '',
        rememberMe: false,
        code: null
      },
      state: {
        resendSignUp: false,
        changePassword: false,
        loading: false,
        resetPassword: false,
        error: null
      },
      sideImg: require('@/assets/images/illustration/playing-music-animate.svg'),
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    hasError() {
      return this.state.error != null
    },
  },
  methods: {
    async login() {
      this.state.loading = true
      this.state.error = null
      await Auth.signIn(this.account.username, this.account.password)
          .then(async user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              this.state.changePassword = true
            }
            else {
              const currentUser = await Auth.currentAuthenticatedUser();
              const { attributes } = currentUser;
              const account = {
                id: attributes['custom:user_id'],
                username: currentUser.username,
                groups: currentUser?.signInUserSession?.accessToken?.payload['cognito:groups'] || [],
              }
              await setAccount(account).then(async () => {
                  await this.$router.push({name: 'dashboard'})
              })
            }
          })
          .catch(error => {
            console.error(error)

            if(error?.code === 'PasswordResetRequiredException') {
              this.state.resetPassword = true
            }

            if(error.message.includes('Temporary password has expired')) {
              this.state.resendSignUp = true
            }

            this.state.error = error.message
          })
          .finally(() => {
            this.state.loading = false
          })
    },

    async setNewPassword() {
      await Auth.signIn(this.account.username, this.account.password).then(async user => {
        Auth.completeNewPassword(user, this.account.newPassword)
            .then(async () => {
              //await adminConfirmSignUp(this.account.username)
              await setAccount({
                id: null, //neither signIn nor completeNewPassword provide the id when including the challengeName
                username: this.account.username
              })
              .then(async () => {
                await this.$router.push({ name: 'account-settings', query: { tab: 'verify' } })
              })
            })
            .catch(e => {
              console.error(e);
            });
      })
    },

    async resendSignUp() {
      await Auth.resendSignUp(this.account.username).then(() => {
        this.state.resendSignUp = false
      }).catch(error => console.error(error))
    },

    async resetPassword() {
      this.state.loading = true
      Auth.forgotPasswordSubmit(this.account.username, this.account.code, this.account.newPassword)
        .then(() => {
          this.state.resetPassword = false
          this.state.error = null
        })
        .catch((error) => {
          this.state.error = error.message
        })
        .finally(() => {
          this.state.loading = false
        });
    }
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/pages/page-auth.scss';
</style>
